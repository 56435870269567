import React from "react";
import { Link } from "gatsby";
import CTAImage from "../images/card-digital-marketing-image.jpg"

const CTA = () => {

    return(
        <div className="relative bg-gray-900">
            <div className="relative h-80 overflow-hidden bg-indigo-600 md:absolute md:left-0 md:h-full md:w-1/3 lg:w-1/2">
            <img alt="" src={CTAImage} className="h-full w-full object-cover" />
            </div>
            <div className="relative mx-auto max-w-7xl py-24 sm:py-32 lg:px-8 lg:py-32">
                <div className="pl-6 pr-6 md:ml-auto md:w-2/3 md:pl-16 lg:w-1/2 lg:pl-24 lg:pr-0 xl:pl-32">
                    <h2 className="text-base font-semibold leading-7 text-blue-400">It's Time To Upgrade Your Digital Presence</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Get In Touch With Us Today</p>
                    <p className="mt-6 text-xl leading-8 text-gray-300">
                        At HealthOne, we specialize in crafting elegant and effective websites tailored to your healthcare practice. 
                        Whether you're seeking to elevate your online presence or have specific requirements, we are ready to assist.
                    </p>
                    <div className="mt-8">
                    <Link to="/contact-us" className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-900 bg-white hover:bg-indigo-50 sm:w-auto">
                        Contact Us
                    </Link>
                    </div>
                </div>
            </div>
      </div>
    )
}

export default CTA;