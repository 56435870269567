import * as React from "react";
import { Link } from "gatsby";
import Layout from '../components/Layout';
import AboutUsHeaderImage from '../svg/Medical-BG7.svg';
import AboutUsImage from '../images/DoctorWebDesign-1200.jpg';
import OurProcessImage from '../images/our-process.jpg';
import OurProcessImage2 from '../images/our-process2.jpg';
//import FoundersImage from '../images/HealthOneDigital-Rana-Edit.jpg';
import SEO from '../components/Seo';
import CTA from "../components/CTA";


const AboutUs = () => {

    return(
        <Layout>
             <SEO title="About Us" description={" HealthOne Digital is a comprehensive digital agency focused on healthcare and technology. As both these industries see wider integration, we're helping healthcare professionals navigate this transition."}/>
            <div className="pt-10 pb-16 bg-blue-100 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                <div className="mx-auto max-w-7xl lg:px-8">
                    <div className="lg:grid lg:grid-cols-1 lg:gap-8">

                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 text-center sm:text-center lg:px-0 lg:flex lg:items-center">
                            <div className="lg:py-24">
                                <h1 className="mt-4 text-5xl tracking-tight font-extrabold sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                    <span className="block text-blue-900">About <span className="text-blue-500">Us</span></span>
                                </h1>

                                <p className="mt-3 text-lg text-blue-900 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                HealthOne Digital is a comprehensive digital agency focused on healthcare and technology. As both these industries see wider integration, we're helping healthcare professionals navigate this transition.
                                </p>

                                <div className="mt-10">
                                    <Link to="/contact-us" className="inline-flex px-5 py-3 border border-transparent text-base justify-center font-medium rounded-md shadow-sm text-blue-900 hover:text-white bg-gradient-to-r from-white to-white hover:from-blue-700 hover:to-blue-700">
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {/* <div className="lg:relative">
                        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                            <AboutUsHeaderImage className="w-full h-auto lg:absolute lg:inset-y-0" alt=""/>
                        </div>
                        </div> */}

                    </div>
                </div>
            </div>

            {/* Content */}
            <section>
                <div className="relative bg-white py-8 lg:py-16">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                    <div className="relative mt-10 sm:py-16 lg:py-0">
                            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:left-72">
                                <div className="absolute inset-y-0 left-1/2 w-full bg-gray-50 rounded-r-3xl lg:left-72"></div>
                                <svg className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
                                <defs>
                                    <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                                </svg>
                            </div>
                            
                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                <div className="relative pt-64 pb-48 rounded-2xl shadow-xl overflow-hidden">
                                    <img className="absolute inset-0 h-full w-full object-cover" src={AboutUsImage} alt="A healthcare professional working on the UX design of a website"/>
                                </div>
                            </div>
                        </div>
                        <div className="relative mx-auto px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                            <div className="pt-12 sm:pt-0 lg:pt-12">
                                <h2 className="text-3xl text-blue-900 font-extrabold tracking-tight sm:text-4xl">
                                We're truly passionate about health and technology.
                                </h2>
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-lg">
                                    At HeathOne Digital, we are dedicated to transforming the digital landscape of healthcare by creating seamless and engaging 
                                    online experiences for both healthcare providers and their patients. With a deep understanding of the unique challenges 
                                    and opportunities within the healthcare industry, our mission is to bridge the gap between quality care and innovative 
                                    technology, ensuring that accessing and delivering healthcare services is efficient, intuitive, and patient-centered.
                                    </p>
                                    <p className="text-lg">
                                    Our team of experienced designers, developers, and strategists collaborates closely with healthcare practices to develop 
                                    customized digital solutions that meet their specific needs. From responsive website designs and secure patient portals 
                                    to integrated appointment scheduling and telehealth platforms, we leverage the latest technologies and best practices 
                                    to enhance patient engagement and streamline operational workflows. We prioritize accessibility and compliance at every 
                                    step, ensuring that our solutions not only look exceptional but also adhere to industry standards and regulations.
                                    </p>
                                    <p className="text-lg">
                                    We believe that a robust online presence is integral to delivering exceptional healthcare in today's digital age. By partnering with 
                                    HealthOne Digital, healthcare providers can expand their reach, improve patient satisfaction, and optimize their services for better 
                                    health outcomes. We are committed to ongoing support and innovation, continually adapting our strategies to meet the evolving needs 
                                    of the healthcare community and the patients they serve. Together, we can create a healthier future powered by technology and compassion.
                                    </p>
                                   
                                </div>
                            </div>
                        </div>

                     
                    </div>
                </div>
            </section>


             {/* CTA */}
            <CTA/>
            

        </Layout>
        
    )
}

export default AboutUs;